/* ========================================================================
 * Photo Gallery
 * ========================================================================
 *
 * Responsive photo gallery
 * ======================================================================== */

+ function($, cb) {
  'use strict';

  cb.apricot.photoGallery = function(element, options) {
    var
      defaultOptions = {
        galleryRange1: 1146,
        galleryRange2: 1048,
        galleryRange3: 754,
        galleryRange4: 656,
        galleryRange5: 290,
        photoRange1: 550,
        photoRange2: 450,
        photoRange3: 350,
        allowedTouchTime: 500,
        modalLabel: '',
        altTxt: 'Photo Gallery'
      },
      plugin = this,
      column = 0,
      hasDesc = false,
      supportsTouch = false,
      singleImg = false,
      browser = cb.apricot.utils.browser(),
      infoId = cb.apricot.utils.uniqueID(5, 'apricot_'),
      highContrast = cb.apricot.utils.isHighContrast();

    plugin.$el = $(element);

    var
      thumbnailLayoutClass = function() {
        var
          cWidth = parseInt(plugin.$el.width(), 10);

        if (cWidth >= plugin.options.galleryRange2) {
          //1146 - 1048: 5

          column = 5;
          plugin.layoutType = 'cb-photo-gallery-5';
        } else if (cWidth <= (plugin.options.galleryRange2 - 1) && cWidth >= plugin.options.galleryRange3) {
          //1047 - 754: 4

          column = 4;
          plugin.layoutType = 'cb-photo-gallery-4';
        } else if (cWidth <= (plugin.options.galleryRange3 - 1) && cWidth >= plugin.options.galleryRange4) {
          //753 - 656: 3

          column = 3;
          plugin.layoutType = 'cb-photo-gallery-3';
        } else if (cWidth <= (plugin.options.galleryRange4 - 1) && cWidth >= plugin.options.galleryRange5) {
          //655 - 290: 2

          column = 2;
          plugin.layoutType = 'cb-photo-gallery-2';
        } else if (cWidth < plugin.options.galleryRange5) {
          //290: 1

          column = 1;
          plugin.layoutType = 'cb-photo-gallery-1';
        }

        plugin.imgType = '';
        if (cWidth <= (plugin.options.photoRange1 - 1) && cWidth >= plugin.options.photoRange2) {

          plugin.imgType = 'cb-photo-size-1';
        } else if (cWidth <= (plugin.options.photoRange2 - 1) && cWidth >= plugin.options.photoRange3) {

          plugin.imgType = 'cb-photo-size-2';
        } else if (cWidth < plugin.options.photoRange3) {

          plugin.imgType = 'cb-photo-size-3';
        }

        $('ul', plugin.$el).first()
          .attr('class', '')
          .addClass(plugin.layoutType)
          .addClass(plugin.imgType);
      },

      // parse gallery to get data
      retrieveDataset = function() {
        var
          items = [],
          obj = {};

        $('.' + plugin.layoutType + ' > li', plugin.$el).each(function(index) {
          var
            self = $(this),
            $thumbImg = $('.cb-photo-thumb', self),
            $thumbInfo = $('.cb-photo-thumb-info', self),
            $detailsInfo = $('.cb-photo-details-info', self),
            img = '',
            alt = '',
            title = '',
            $title = {},
            subTitleArr = [],
            detailsObj = {};

          self.attr('data-cb-thumb', index);

          // reset
          obj = {};

          // large image
          img = $thumbImg.attr('data-cb-img');
          alt = (!!$('img', $thumbImg).attr('alt')) ? $('img', $thumbImg).attr('alt') : plugin.options.altTxt;

          if ($thumbInfo.children().length > 0) {
            hasDesc = true;
          }

          // main: title
          $title = $('h2, h3, h4, h5, h6', $thumbInfo);
          if ($title.length > 0) {
            title = $title.html();
          }

          // main: sub titles
          $('p', $thumbInfo).each(function() {
            subTitleArr.push($(this).html());
          });

          detailsObj = {};
          detailsObj = $detailsInfo.html();

          if ($detailsInfo.children().length > 0) {
            hasDesc = true;
          }

          obj.img = img;
          obj.alt = alt;

          obj.title = title;
          obj.titleTag = (!!$title[0]) ? $title[0].tagName.toLowerCase() : '';
          obj.subTitleArr = subTitleArr;
          obj.details = detailsObj;

          items.push(obj);
        });

        if (items.length == 1) {
          singleImg = true;
        }

        plugin.items = items;
        plugin.count = $('.' + plugin.layoutType + ' > li', plugin.$el).length;
      },

      // show gallery modal on click
      thumbnailEvents = function() {
        $('.' + plugin.layoutType + ' > li', plugin.$el).each(function() {
          var
            self = $(this);

          // mouse, click
          self.on('click.cbPhotoGallery', function(e) {
            e.stopPropagation();
            e.preventDefault();

            var
              current = self.attr('data-cb-thumb'),
              data = plugin.items[current];

            setGalleryData(data, current);
          });

          // accessibility, enter/space
          self.on('keydown.cbPhotoGallery', function(e) {
            if (!!cb.apricot.utils.isKey(e, 'ENTER') || !!cb.apricot.utils.isKey(e, 'SPACE')) {
              e.stopPropagation();
              e.preventDefault();

              var
                current = self.attr('data-cb-thumb'),
                data = plugin.items[current];

              setGalleryData(data, current);
            }
          });

          $('a', self).on('blur.cbPhotoGallery', function() {

            $(this).removeClass('focus');
          }).on('focus.cbPhotoGallery', function() {

            $(this).addClass('focus');
          });
        });
      },

      // next/prev arrows
      // TBD: add desctiption to tab order
      navigationEvents = function() {
        var
          $close = $('.close', plugin.$modal),
          $next = $('.next-img', plugin.$modal),
          $prev = $('.prev-img', plugin.$modal),
          $nextHit = $('.cb-hit-right', plugin.$modal),
          $prevHit = $('.cb-hit-left', plugin.$modal),
          $des = $('.gallery-des', plugin.$modal),

          selected = 0,
          next = 0,
          previous = 0,
          jump = true,
          data = {};

        if (!!singleImg) {
          return false;
        }

        // close
        $close.keydown(function(e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();
            $prev.focus();
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();

            if ($('.main-title', $des).length > 0) {
              jump = false;
              $des.focus();
            } else {

              $next.focus();
            }
          }
        });

        // hit right
        $nextHit.on('mouseover.cbPhotoGallery', function() {

          $next.addClass('hover');
        });

        $nextHit.on('mouseleave.cbPhotoGallery', function() {

          $next.removeClass('hover');
        });

        $nextHit.on('click.cbPhotoGallery', function(e) {
          e.preventDefault();

          $next.trigger('click.cbPhotoGallery');
        });

        // hit left
        $prevHit.on('mouseover.cbPhotoGallery', function() {

          $prev.addClass('hover');
        });

        $prevHit.on('mouseleave.cbPhotoGallery', function() {

          $prev.removeClass('hover');
        });

        $prevHit.on('click.cbPhotoGallery', function(e) {
          e.preventDefault();

          $prev.trigger('click.cbPhotoGallery');
        });


        // Description accessibility
        $des.on('keydown.cbPhotoGallery', function(e) {

          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();
            jump = true;

            $close.focus();
          }

          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            jump = true;

            $next.focus();
          }
        }).on('focusout', function () {
          if (!jump) {
            if (!!browser.firefox) {
              setTimeout(function() {
                $des.focus();
              }, 0);
            } else {
              $des.focus();
            }
          }
        });

        // arrows
        $next.off('click.cbPhotoGallery').on('click.cbPhotoGallery', function() {
          selected = $(this).attr('data-cb-item');
          data = plugin.items[selected];
          next = getItemsNumber(0, selected);
          $(this).attr('data-cb-item', next);

          setGalleryData(data, selected);
        });

        // accessibility, enter/space
        $next.on('keydown.cbPhotoGallery', function(e) {
          if (!!cb.apricot.utils.isKey(e, 'ENTER') || !!cb.apricot.utils.isKey(e, 'SPACE')) {
            e.preventDefault();

            $next.trigger('click.cbPhotoGallery');
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();

            if ($('.main-title', $des).length > 0) {
              jump = false;
              $des.focus();
            } else {
              $close.focus();
            }
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();

            $prev.focus();
          }
        });

        $prev.off('click.cbPhotoGallery').on('click.cbPhotoGallery', function() {
          selected = $(this).attr('data-cb-item');
          data = plugin.items[selected];
          previous = getItemsNumber(0, selected);
          $(this).attr('data-cb-item', previous);

          setGalleryData(data, selected);
        });

        // accessibility, enter/space
        $prev.on('keydown.cbPhotoGallery', function(e) {
          if (!!cb.apricot.utils.isKey(e, 'ENTER') || !!cb.apricot.utils.isKey(e, 'SPACE')) {
            e.preventDefault();

            $prev.trigger('click.cbPhotoGallery');
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
            e.preventDefault();
            $next.focus();
          }
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $close.focus();
          }
        });

        // add listener
        // touch and mousemove
        cb.apricot.utils.swipe($('.cb-gallery-image', plugin.$modal));

        $('.cb-gallery-image', plugin.$modal).on('swipe_end', function (e, data) {

          if (!!data) {
            if (data.offset.x !== 0) {
              if (data.direction.x === 'left') {
                $prev.trigger('click.cbPhotoGallery');
              } else if (data.direction.x === 'right') {
                $next.trigger('click.cbPhotoGallery');
              }
            }
          }
        });
      },


      // mode: 1, previous
      // mode: 0, next
      getItemsNumber = function(mode, current) {
        var
          number = 0,
          count = parseInt(plugin.count, 10);

        current = parseInt(current, 10);

        if (!!mode) {
          // previous
          number = (current > 0) ? current - 1 : count - 1;
        } else {
          // next
          number = (current < count - 1) ? current + 1 : 0;
        }

        return number;
      },

      // update modal
      setGalleryData = function(data, current) {
        var
          previous = getItemsNumber(1, current),
          next = getItemsNumber(0, current),
          $des = $('.gallery-des', plugin.$modal)
            // .css('opacity', '0')
            .empty(),
          $img = $('.cb-gallery-image', plugin.$modal)
            .empty(),
          count = parseInt(plugin.count, 10);

        current = parseInt(current, 10) + 1;

        // reset
        $('.cb-gallery-image', plugin.$modal).css('opacity', '0');

        $('.count-num', plugin.$modal).html(count);
        $('.current-num', plugin.$modal).html(current);

        $('.next-img', plugin.$modal).attr('data-cb-item', next)
          .attr('aria-label', 'next gallery image, show image ' + parseInt(next + 1, 10) + ' of ' + count);

        $('.prev-img', plugin.$modal).attr('data-cb-item', previous)
          .attr('aria-label', 'previous gallery image, show image ' + parseInt(previous + 1, 10) + ' of ' + count);

        if (!!data.img) {
          $img
            .css('background-image', 'url(' + data.img + ')')
            .attr('data-cb-thumb', current)
            .animate({ opacity: 1 }, 200);

          if (!!highContrast) {
            $img.addClass('cb-high-contrast')
            getImgDimension(data.img, $img);
          }
        }

        $('<span />')
          .addClass('sr-only')
          .html('Gallery Image ' + current + ' of ' + count + ', ' + data.alt)
          .appendTo($img);

        plugin.$modal.attr('aria-label', 'Gallery Image ' + current + ' of ' + count);

        // set header tag based on thumb section
        if (data.titleTag !== '') {
          $('<' + data.titleTag + ' />')
            .html(data.title)
            .addClass('main-title')
            .appendTo($des);
        }

        for (var st in data.subTitleArr) {
          $('<p />')
            .addClass('main-info')
            .html(data.subTitleArr[st])
            .appendTo($des);
        }

        $des.append(data.details);
          // .animate({ opacity: 1 }, 200);

        plugin.$modal
          .on('hidden.bs.modal', function() {
            var
              current = $('.cb-gallery-image', plugin.$modal).attr('data-cb-thumb'),
              $elm = $('[data-cb-thumb="' + parseInt(current -1 ,10) + '"]', plugin.$el);


            plugin.$el.trigger('close.cbPhotoGallery');

            $('a', $elm).addClass('focus')
              .focus();
          })
          .on('shown.bs.modal', function() {
            var
              $close = $('.close', plugin.$modal).focus(),
              $next = $('.next-img', plugin.$modal),
              $prev = $('.prev-img', plugin.$modal);

            plugin.$el.trigger('open.cbPhotoGallery');

            // Accessibility
            $close.keydown(function(e) {
              if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
                e.preventDefault();
                $prev.focus();
              }
              if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
                e.preventDefault();
                $next.focus();
              }
            });

            $prev.keydown(function(e) {
              if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
                e.preventDefault();
                $next.focus();
              }
              if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
                e.preventDefault();
                $close.focus();
              }
            });

            $next.keydown(function(e) {
              if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
                e.preventDefault();
                $close.focus();
              }
              if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
                e.preventDefault();
                $prev.focus();
              }
            });
          })
          .modal('show');
      },

      buildGalleryModal = function() {
        var
          $divModal = $('<div />')
          .addClass('modal')
          .addClass('cb-photo-gallery-modal')
          .addClass('cb-modal-animation')
          .addClass('fade')
          .attr('tabindex', '-1')
          .attr('role', 'dialog')
          .attr('aria-label', plugin.options.modalLabel)
          .attr('id', plugin.modalId),

          $divDialog = $('<div />')
          .addClass('modal-dialog')
          .attr('role', 'document')
          .appendTo($divModal),

          $divContent = $('<div />')
          .addClass('modal-content')
          .appendTo($divDialog),

          $divGallery = $('<div />')
          .addClass('gallery-container')
          .appendTo($divContent),

          $divInfo = $('<div />')
          .addClass('info-container')
          .appendTo($divContent),

          // Info Header
          // parent: info-container
          $divNav = $('<div />')
          .addClass('gallery-header')
          .appendTo($divInfo),

          $pCount = $('<p />')
          .addClass('count-info')
          .attr('role', 'heading')
          .attr('aria-level', 3)
          .attr('aria-live', 'polite')
          .attr('id', infoId)
          .appendTo($divNav);

        $('<span />')
          .addClass('current-num')
          .addClass('gallery-counter')
          .appendTo($pCount);

        $('<span />')
          .addClass('sr-only')
          .html(' of ')
          .appendTo($pCount);

        $('<span />')
          .addClass('count-num')
          .addClass('gallery-counter')
          .appendTo($pCount);

        var $btn = $('<button />')
          .addClass('close')
          .attr('data-dismiss', 'modal')
          .attr('aria-label', 'Close')
          .appendTo($divNav);

        $('<span />')
          .attr('aria-hidden', true)
          .html('×')
          .appendTo($btn);

        //Info Description
        // parent: info-container
        $('<div />')
          .addClass('gallery-des')
          .attr('tabindex', '0')
          .attr('role', 'region')
          .attr('aria-label', 'Gallery Image Description')
          .appendTo($divInfo);

        // mobile/tablet header
        var $mtHeader = $divNav.clone();

        $divDialog.before($mtHeader);
        $mtHeader.addClass('hidden-md')
          .addClass('hidden-lg')
          .addClass('mt-header');

        // accessibility, add unique ID
        $('.count-info', $mtHeader).attr('id', cb.apricot.utils.uniqueID(5, 'apricot_'));

        // desktop/oversized only
        $divNav.addClass('hidden-sm')
          .addClass('hidden-xs');

        // Images
        // parent: gallery-container
        var $divArrowLeft = $('<div />')
          .addClass('gallery-navigation')
          .addClass('prev-img')
          .appendTo($divGallery);

        if (!singleImg) {
          $divArrowLeft.attr('tabindex', '0')
            .attr('role', 'link')
            .attr('aria-label' ,'previous gallery image')
            .attr('aria-controls', infoId);
        }

        $('<span />')
          .addClass('cb-glyph')
          .addClass('cb-left')
          .appendTo($divArrowLeft);

        var $divImg = $('<div />')
          .addClass('cb-gallery-image')
          .appendTo($divGallery);

        $('<div />')
          .addClass('cb-hit-left')
          .appendTo($divImg);

        $('<div />')
          .addClass('cb-hit-right')
          .appendTo($divImg);

        var $divArrowRight = $('<div />')
          .addClass('gallery-navigation')
          .addClass('next-img')
          .appendTo($divGallery);

        if (!singleImg) {
          $divArrowRight.attr('tabindex', '0')
            .attr('role', 'link')
            .attr('aria-label' ,'next gallery image')
            .attr('aria-controls', infoId);
        }

        $('<span />')
          .addClass('cb-glyph')
          .addClass('cb-right')
          .appendTo($divArrowRight);

        if (!hasDesc) {
          $divModal.addClass('cb-no-desc');
        }

        if (!!singleImg) {
          $divModal.addClass('cb-single-img');
        }

        // add modal to page
        $('body').append($divModal);

        plugin.$modal = $('#' + plugin.modalId);
      },

      setMobileMode = function(prefix) {
        if (prefix === 'xs' || prefix === 'sm') {

          plugin.$modal.addClass('cb-photo-gallery-mt');
        } else {

          plugin.$modal.removeClass('cb-photo-gallery-mt');
        }

      },

      getImgDimension = function (src) {
         var
          $img = $('.cb-gallery-image', plugin.$modal),
          img = new Image,
          imgW = 0,
          imgH = 0,
          newW = 0,
          newH = 0;

        img.src = src;
        $(img).on('load', function(){
          imgW = img.width;
          imgH = img.height;

          if(imgW > imgH){
            newW = $img.width();
            newH = imgH / imgW * newW;
          }else{
            newH = $img.height();
            newW = imgW / imgH * newH;
          }

          if (!!isNaN(newW) || newW === 0) {
            newW = 'auto';
          }

          if (!!isNaN(newH) || newH === 0) {
            newH = 'auto';
          }

          $(img).css('width', newW)
            .css('height', newH)
            .appendTo($img);
        });
      };

    plugin.init = function() {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);
      plugin.modalId = cb.apricot.utils.uniqueID(5, 'apricot_');
      plugin.count = 0;
      plugin.items = [];
      plugin.layoutType = '';
      plugin.$modal = {};

      supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

      var currentViewport = cb.apricot.utils.viewport();

      // first make sure the layout is correct
      thumbnailLayoutClass();

      // retrieve data
      retrieveDataset();

      // start building the gallery
      buildGalleryModal();

      // add listener
      setMobileMode(currentViewport.prefix);

      cb.apricot.utils.breakpoints();
      $(document).on('breakpoint_change.cbPhotoGallery', function(e, data) {
        setMobileMode(data.prefix);
      });

      // add events
      thumbnailEvents();

      // modal events
      navigationEvents();

      $(window).on('resize.cbPhotoGallery.' + plugin.modalId, function() {

        thumbnailLayoutClass();
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function() {
      if (!!plugin.$el.data('cbPhotoGallery')) {
        plugin.$el.removeData('cbPhotoGallery');

        $(window).off('resize.cbPhotoGallery.' + plugin.modalId);
        $(document).off('breakpoint_change.cbPhotoGallery');
        // remove Modal
        $('#' + plugin.modalId).remove();
        $('li', plugin.$el).each(function() {
          var
            self = $(this);

          self.off('click.cbPhotoGallery');
          self.off('keydown.cbPhotoGallery');
        });
      }
    };

    plugin.init();
  };

  $.fn.cbPhotoGallery = function(options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function() {
        if (!$(this).data('cbPhotoGallery')) {
          $(this).data('cbPhotoGallery', new cb.apricot.photoGallery(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function() {
        var instance = $.data(this, 'cbPhotoGallery');
        if (instance instanceof cb.apricot.photoGallery && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };
}(jQuery, cb);
