/*!
 *  Apricot v3.2.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-02-26 [5:55:31 PM] UTC
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Tagged Sidebar
 * ========================================================================
 *
 * $.fn.cbTaggedSidebar
 *
 * Build a tree structure which can be used for tracking
 * the scroll position of the page.
 *
 * please use scrollspy.js and affix.js for activating the tracking
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.taggedSidebar = function (element, options) {
    var
      defaultOptions = {
        mainNav: 'sidebar-item',
        subNav: 'sub-sidebar-item',
        backTop: 1,
        navCount: 6,
        contained: 0,
        container: 'body'
      },
      plugin = this,
      $tags = [],
      tagList = [];

      plugin.$el = $(element);
      plugin.el = element;

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      $tags = $(plugin.options.container).find('[data-cb-tags="' + plugin.options.mainNav + '"]');
      plugin.$el .addClass('hidden-print')
              .addClass('hidden-xs')
              .addClass('hidden-sm')
              .attr('role', 'complementary');


      getTaggedList();
      if (tagList.length > 0) {
        plugin.$el.append(buildSidebar());

        $(document).trigger('tagged_sidebar', true);
      }
    };

    var getTaggedList = function () {
      $tags.each(function () {
        var
          self = $(this),
          tagObj = {};

        tagObj.name = self.html();
        tagObj.tag = self.attr('id');

        if (!!plugin.options.contained) {
          tagObj.list = getTagObj(self.parent().find('[data-cb-tags="' + plugin.options.subNav + '"]'));
        } else {
          tagObj.list = getTagObj(self.nextUntil('[data-cb-tags="' + plugin.options.mainNav + '"]', '[data-cb-tags="' + plugin.options.subNav + '"]'));
        }

        tagList.push(tagObj);
      });
    },

    getTagObj = function ($this) {
      var
        obj = {},
        objList = [],
        secondLevelObjList = [];

      $this.each(function () {
        var
          self = $(this);

        secondLevelObjList = [];

        //Has Sub List
        if (typeof self.attr('data-cb-parent') !== 'undefined' && self.attr('data-cb-level-check') !== 'add') {
          self.attr('data-cb-level-check', 'add');

          $('[data-cb-parent="' + self.attr('data-cb-parent') + '"]').each(function () {
            var
              self = $(this);

            self.attr('data-cb-level-check', 'add');
            obj = {};
            obj.name = self.html().replace(/<\/?[^>]+(>|$)/g, '').replace(/^\s+|\s+$/g, '');
            obj.tag = self.attr('id');
            obj.subList = [];
            secondLevelObjList.push(obj);
          });

          objList[objList.length - 1].subList = secondLevelObjList;

        } else if (typeof self.attr('data-cb-parent') === 'undefined' ) {
          obj = {};
          obj.name = self.html().replace(/<\/?[^>]+(>|$)/g, '').replace(/^\s+|\s+$/g, '');
          obj.tag = self.attr('id');
          obj.subList = [];
          objList.push(obj);
        }
      });

      return objList;
    },

    buildSidebar = function () {
      var
        sideBar = '<ul class="nav cb-tagged-sidenav">';

      $.each(tagList, function (key, value) {
        sideBar += '<li';
        if (value.list.length > 0) {
          sideBar += ' class="cb-nested"';
        }
        sideBar += '>';
        sideBar += '<a href="#' + value.tag + '">' + value.name + '</a>';

        if (value.list.length > 0) {
          sideBar += buildSubList(value.list, 0 );
        }

        sideBar += '</li>';
      });

      sideBar += '</ul>';
      if (!!plugin.options.backTop && tagList.length >= plugin.options.navCount) {
        sideBar += '<a class="back-to-top" href="#top">Back to top</a>';
      }
      return sideBar;
    },

    buildSubList = function (list, type) {
      var
          sideBar = '<ul class="nav ' + type + ' ">';

      $.each(list, function (key, value) {
        sideBar += '<li>';
        sideBar += '<a href="#' + value.tag + '">' + value.name + '</a>';

        if (value.subList.length > 0) {
          sideBar += buildSubList(value.subList, 1);
        }

        sideBar += '</li>';
      });
      sideBar += '</ul>';

      return sideBar;
    };

    plugin.init();
  };

  $.fn.cbTaggedSidebar = function (options) {
    return this.each(function () {
      if (!$(this).data('cbTaggedSidebar')) {
        $(this).data('cbTaggedSidebar', new cb.apricot.taggedSidebar(this, options));
      }
    });
  };
}(jQuery, cb);
