/* ========================================================================
 * Apricot's setup Module
 * ========================================================================
 *
 * function to overwrite default apricot behavior
 *
 * setup object
 *   element: {
 *     mode: 'auto|manual',
 *     selector:['#id','.className'] //all jQuery selectors are accepted
 *   }
 *
 * To override a specific Apricot behavior, call cb.apricot.setup.behavior()
 * pass in an object that describes the modified behavior settings.
 * For example:
 *
 * cb.apricot.setup.behavior({
 *   checkbox: {
 *     mode: 'manual'
 *   }
 * });
 *
 * This tells Apricot to not introduce custom checkbox behavior when the document
 * is loaded.
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  var
    defaultOptions = {
      // $.fn.cbCustomElement
      checkbox: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbFileUpload
      fileUpload: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbInputPlaceholder
      placeholder: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbCustomElement
      radio: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbCustomElement
      select: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbCustomScrollbar
      table: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbResponsiveImage
      responsiveImage: {
        mode: 'auto',
        selector: []
      },
      // $.fn.cbTabsToDropdown
      tabsToDropdown: {
        mode: 'auto',
        selector: []
      },
      //$.fn.cbTextareaResize
      textArea: {
        mode: 'auto',
        selector: []
      },
      //input method listener
      inputMethod: {
        mode: 'auto',
        selector: []
      },
      //cb.apricot.clickJacking()
      clickJacking: {
        mode: 'auto',
        selector: []
      }
    };

  function _behavior(options) {
    if (typeof options === 'object') {
      $.extend(true, defaultOptions, options);
    }

    return defaultOptions;
  }

  function _elementBehavior(element) {
    var
      obj = { //Default setup object
        mode: 'auto',
        selector: []
      };

    if (!!defaultOptions[element]) {
      obj = defaultOptions[element];
    }

    return obj;
  }

  function _isCb(element) {
    if (_elementBehavior(element).mode === 'auto'){
      return true;
    } else {
      return false;
    }
  }

  function _hasSelector(element) {
    if (_elementBehavior(element).selector.length > 0 ){
      return true;
    } else {
      return false;
    }
  }

  function _selector(element) {
    return _elementBehavior(element).selector;
  }

  // ----- cb Apricot setup module public API
  var _setup = {
    behavior: _behavior,
    selector: _selector,
    isCb: _isCb,
    hasSelector: _hasSelector
  };

  cb.apricot.setup = _setup;

}(jQuery, cb);
