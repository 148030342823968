/* ========================================================================
 * Breadcrumbs
 * ========================================================================
 *
 * Style and behaivior adjustment for Breadcrumbs
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.breadcrumbs = function (element, options) {
    var
      defaultOptions = {
        ellipseText: '...',
        maxChars: 15
      },
      $last = {},
      $parent = {},

      maxChars = 0,
      num = 0,
      browser = {},

      plugin = this;

    plugin.$el = $(element);

    var
    checkMenuStructure = function () {
      var
        iw = itemsWidth(),
        cw = containerWidth();

      // items wont fit
      if (iw >= cw) {

        decrease();
      } else { //reset

        increase();
      }
    },

    checkWidth = function () {
      var
        iw = itemsWidth(),
        cw = containerWidth();

      // items wont fit
      if (iw > cw) {

        return false;
      } else {

        return true;
      }
    },

    // decrease items width
    decrease = function () {
      var step = (!!plugin.$el.attr('data-cb-step')) ?
        parseInt(plugin.$el.attr('data-cb-step'), 10) : 1;

      switch(step) {
        case 1:
          adjustStep1 (true);
          break;
        case 2:
          adjustStep2 (true);
          break;
        case 3:
          adjustStep3 (true);
          break;
      }

      // what is the next step
      if (step < 3) {
        step += 1;
        plugin.$el.attr('data-cb-step', step);
      } else {
        return false;
      }

      // still wont fit
      if (!checkWidth()) {
        decrease();
      } else {
        return false;
      }
    },

    // increase items width
    increase = function () {
      var step = (!!plugin.$el.attr('data-cb-step')) ?
        parseInt(plugin.$el.attr('data-cb-step'), 10) : 1;

      switch(step) {
        case 1:
          adjustStep1 (false);
          break;
        case 2:
          adjustStep2 (false);
          break;
        case 3:
          adjustStep3 (false);
          break;
      }

      // new changes wont fit
      // undo changes
      if (!checkWidth()) {

        decrease();
      } else { // continue adding

        // what is the next step
        if (step > 1) {
          step -= 1;
          plugin.$el.attr('data-cb-step', step);

          increase();
        } else {

          return false;
        }
      }
    },

    itemsWidth = function () {
      var
        bWidth = 0;

      $('li', plugin.$el).each(function () {
        var
          self = $(this);

        if (!self.hasClass('hidden')) {
          bWidth += self.outerWidth(true);
        }
      });

      return bWidth;
    },

    containerWidth = function () {

      return plugin.$el.parent().outerWidth(true);
    },

    reset = function () {
      // step 1
      adjustStep1(false);

      // step 2
      adjustStep2(false);

      // step 3
      adjustStep3(false);

      plugin.$el.attr('data-cb-step', 1);
      plugin.$el.addClass('cb-has-collapse');
      $('li:nth-child(2) a', plugin.$el).focus();
    },

    adjustStep1 = function (mode) {
      var
        $a = $('<a>', {
            'class': 'cb-truncate-show',
            'href' : '#'})
          .html('<span class="sr-only">show collapsed breadcrumb items</span>' + plugin.options.ellipseText),
        $truncate = $('<li>', {'class': 'cb-truncate'})
            .prepend($a),
        $collapse = $('<a>', {
            'class': 'cb-truncate-hide hide',
            'href' : '#'})
          .attr('aria-label', 'Collapse breadcrumb navigation')
          .html('Collapse');

        if (!!browser.firefox) {
          $truncate.attr('tabindex', '-1');
        }

      if (!!mode) {
        for (var i = 2; i < num - 1; i++) {
          $('[data-cb-bnav="' + i + '"]', plugin.$el).each( function() {
          var
            self = $(this);

            self.addClass('hidden');
            $('a', self).attr('tabindex', '-1')
              .attr('aria-hidden', 'true');
          });
        }

        $truncate.insertBefore($parent);
        if ($('.cb-truncate-hide', plugin.$el).length > 0) {
          $('.cb-truncate-hide', plugin.$el).addClass('hide');
          plugin.$el.removeClass('cb-has-collapse');
        } else {
          $('li', plugin.$el).last().append($collapse);
        }

        // add events
        $('.cb-truncate-show', plugin.$el).off('click.cbBreadcrumbs').on('click.cbBreadcrumbs', function(e) {
            e.preventDefault();
            plugin.$el.addClass('cb-activate-collapse');

            reset();
        });

        $('.cb-truncate-hide', plugin.$el).off('click.cbBreadcrumbs').on('click.cbBreadcrumbs', function(e) {
            e.preventDefault();

            $('.cb-truncate-hide', plugin.$el).remove();
            plugin.$el.removeClass('cb-has-collapse');
            plugin.$el.removeClass('cb-activate-collapse');
            checkMenuStructure();

            $('li:nth-child(1) a', plugin.$el).focus();
        });
      } else {
        $('li.hidden', plugin.$el).each( function() {
          var
            self = $(this);

          self.removeClass('hidden');
          $('a', self).attr('tabindex', '0')
            .attr('aria-hidden', 'false');
        });

        $('.cb-truncate', plugin.$el).remove();
        if (!!plugin.$el.hasClass('cb-activate-collapse')) {
          $('.cb-truncate-hide', plugin.$el).removeClass('hide');
        }
      }
    },

    adjustStep2 = function (mode) {
      var
        $last = $('li', plugin.$el).last(),
        $span = $('span:not(.sr-only)', $last),
        txt = (!!$span.attr('data-cb-org')) ?
          $span.attr('data-cb-org') :
          $span.html(),
        txtTruncate = (txt.length > 15) ?
          cb.apricot.utils.textTruncate(txt, maxChars, 'last', plugin.options.ellipseText) :
          txt;

      $span.attr('data-cb-org', txt)
        .attr('aria-label', txt);

      if (!!mode) {
        $span.html(txtTruncate);
      } else {
        $span.html($span.attr('data-cb-org'));
      }
    },

    adjustStep3 = function (mode) {
      var
        $a = $('a', $parent),
        txt = (!!$a.attr('data-cb-org')) ?
          $a.attr('data-cb-org') :
          $a.html(),
        txtTruncate = (txt.length > 15) ?
          cb.apricot.utils.textTruncate(txt, maxChars, 'last', plugin.options.ellipseText) :
          txt;

      $a.attr('data-cb-org', txt)
        .attr('aria-label', txt);

      if (!!mode) {
        $a.html(txtTruncate);
      } else {
        $a.html($a.attr('data-cb-org'));
      }
    };

    plugin.init = function () {

      plugin.options = $.extend({}, defaultOptions, options);

      browser = cb.apricot.utils.browser();
      num = $('li', plugin.$el).length;
      maxChars = plugin.options.maxChars + plugin.options.ellipseText.length;
      plugin.$el.addClass('cb-breadcrumb');

      $('li', plugin.$el).each(function (index) {
        var
          self = $(this);

        if (!!browser.firefox) {
          self.attr('tabindex', '-1');
        }

        self.attr('data-cb-bnav', index + 1);
      });

      $last = $('[data-cb-bnav="' + num + '"]', plugin.$el);
      $parent = (num > 1) ?
        $('[data-cb-bnav="' + parseInt(num - 1, 10) + '"]', plugin.$el) :
        $('[data-cb-bnav="' + num + '"]', plugin.$el);

      checkMenuStructure();

      //Check structure on resize
      $(window).on('resize.cbBreadcrumbs', function () {
        if (!!browser.firefox) {
          setTimeout(function() {
            plugin.$el.removeClass('cb-activate-collapse');
            checkMenuStructure();
          }, 100);
        } else {
          plugin.$el.removeClass('cb-activate-collapse');
          checkMenuStructure();
        }
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('li', plugin.$el).each(function () {
          $(this).removeAttr('data-cb-bnav');
      });

      // plugin.$el.removeClass('cb-breadcrumb');

      $(window).off('resize.cbBreadcrumbs');
      plugin.$el.removeData('cbBreadcrumbs');
    };

    plugin.init();
  };

  $.fn.cbBreadcrumbs = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbBreadcrumbs')) {
          $(this).data('cbBreadcrumbs', new cb.apricot.breadcrumbs(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbBreadcrumbs');
        if (instance instanceof cb.apricot.breadcrumbs && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };

  // ----- Activate on page load
  $(window).on('load', function () {
    // Progran Band
    $('.breadcrumb, .cb-breadcrumb').each(function () {
      var
        self = $(this);

      if (self.data('cb-element') !== 'no-cb') {
        self.cbBreadcrumbs();
      }
    });
  });
}(jQuery, cb);
