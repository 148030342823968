/* ========================================================================
 * Jump links
 * ========================================================================
 *
 * Style and behaivior adjustment for Jumplinks
 * ======================================================================== */


+function ($, cb) {
  'use strict';

  cb.apricot.jumpLinks = function (element, options) {
    var
      defaultOptions = {
        jumpLinksClass: 'cb-jumplinks',
        containerClass: 'cb-jumplinks-container',
        linkClass: 'cb-jumplinks-link',

        buttonStyle: false,
        buttonStyleClass: 'cb-jumplinks-btn',

        menuTitle: 'On this page',

        anchorClass: 'cb-jumplinks-anchor',
        anchorAttr: 'jumplinks',
        anchorTag: 'h2',

        labelAttr: 'data-cb-jumplinks-label',

        backLink: true,
        backClass: 'cb-jumplinks-back',
        backLabel: 'Back to Top',

        anchorNoFocus: 'cb-anchor-no-focus',
        localNavClass: 'cb-local-navigation',

        animationScroll: true,
        animationTime: 1000,
        adjustment: 48,
        focusState: false,
        generateSelector: '' //attr|class|tag
      },
      hasLocalNav = false,
      plugin = this;

    plugin.$el = $(element);

    var
    getMenuItems = function () {
      var
        mainItems = [],
        selector = '',
        obj = {};

      switch(plugin.options.generateSelector) {
        case 'attr':
          selector = '[data-cb-element="' + plugin.options.anchorAttr + '"]';
          break;
        case 'class':
          selector = '.' + plugin.options.anchorClass;
          break;
        case 'tag':
          selector = plugin.options.anchorTag;
          break;
      }


      $(selector, $('.' + plugin.options.containerClass)).each(function () {
        var
          self = $(this);

        obj = {};
        obj.label = (!!self.attr(plugin.options.labelAttr)) ? self.attr(plugin.options.labelAttr) : self.html();
        obj.id = (!!self.attr('id')) ? self.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');

        // make sure everything is in place
        self.attr('id', obj.id)
          .addClass(plugin.options.anchorClass);

        mainItems.push(obj);
      });

      return mainItems;
    },

    // add animation to bookmark scroll
    addScrollAnimation = function ($selector) {
      $selector.off('click.cbJumpLinks').on('click.cbJumpLinks', function(e) {
        e.preventDefault();


        var
          adjust = plugin.options.adjustment,
          currentViewport = cb.apricot.utils.viewport(),
          $a = (!!$(e.target).is('a')) ? $(e.target) : $(e.target).closest('a');

        if (!!hasLocalNav) {
          if (currentViewport.prefix === 'sm' || currentViewport.prefix === 'xs') {
            adjust += $('.' + plugin.options.localNavClass + ' .cb-mobile-local-header').height() + 6;
          } else {
            adjust += $('.' + plugin.options.localNavClass).height() + 4;
          }
        }

        if (!!$a.attr('href')) {
          $('body, html').animate({
            scrollTop: $($a.attr('href')).offset().top - adjust
          }, plugin.options.animationTime, function() {

            // Accessibility: Focus on target after animation is complete.
            var $scrollDestination = $($a.attr('href'));

            // See if the ID of the element is capable of being focused. If not, add a tabindex of '-1'
            if (typeof $scrollDestination.attr('tabindex') === 'undefined') {
              $scrollDestination.attr('tabindex', '-1');
            }
            // Focus on the target.
            if (!plugin.options.focusState) {
              $('.' + plugin.options.anchorNoFocus).removeClass(plugin.options.anchorNoFocus);
              $scrollDestination.addClass(plugin.options.anchorNoFocus);
            }

            $scrollDestination.focus();
          });
        }
      });
    },

    // HTML markup for jump links links
    buildJumplinksMenu =  function () {
      // accessibility
      plugin.$el.attr('role', 'navigation')
        .attr('aria-label', 'Page navigation menu');

      var items = getMenuItems();

      var $ul = $('<ul/>')
        .addClass(plugin.options.jumpLinksClass)
        .appendTo(plugin.$el);

      if (!!plugin.options.buttonStyle) {
        $ul.addClass(plugin.options.buttonStyleClass)
      }

      if (!plugin.options.buttonStyle) {
        var $title = $('<li/>')
          .addClass('title');

        $('<h2/>')
          .html(plugin.options.menuTitle)
          .appendTo($title);

        $title.appendTo($ul);
      }

      for (var item in items) {
        var $li = $('<li/>')
          .addClass(plugin.options.linkClass);
        $('<a/>')
          .attr('href', '#' + items[item].id)
          .html(items[item].label)
          .appendTo($li);

        $li.appendTo($ul);
      }
    },

    buildBackToMenu = function () {
      $('a', plugin.$el).each(function () {
        var
          self = $(this),
          id = self.attr('href'),
          $anchor = $(id),
          $back = $('<a/>')
            .addClass(plugin.options.backClass)
            .attr('aria-label', 'back to page navigation menu')
            .attr('href', '#' + plugin.$el.attr('id'));

          $('<span />')
            .addClass('cb-glyph cb-up cb-glyph-circular')
            .attr('aria-hidden', true)
            .appendTo($back);
          $('<span />')
            .html(plugin.options.backLabel)
            .appendTo($back);

        $back.insertBefore($anchor);
      });
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      hasLocalNav = ($('.' + plugin.options.localNavClass).length > 0) ? true : false;

      buildJumplinksMenu();

      if (!plugin.$el.attr('id')) {
        plugin.$el.attr('id', cb.apricot.utils.uniqueID(5, 'apricot_'));
      }

      addScrollAnimation($('.' + plugin.options.linkClass +  ' a', plugin.$el));

      if (!!plugin.options.backLink) {
        buildBackToMenu();
        addScrollAnimation($('.' + plugin.options.containerClass +' .' + plugin.options.backClass));
      }

      // jump links are ready
      plugin.$el.trigger('finished.cbJumpLinks');
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      $('.' + plugin.options.backClass).remove();

      plugin.$el.removeData('cbJumpLinks');
    };

    plugin.init();
  };

  $.fn.cbJumpLinks = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbJumpLinks')) {
          $(this).data('cbJumpLinks', new cb.apricot.jumpLinks(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbJumpLinks');
        if (instance instanceof cb.apricot.jumpLinks && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
