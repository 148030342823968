/* ========================================================================
 * Sibling Menu
 * ========================================================================
 *
 * Style and behaivior adjustment for Sibling Menu
 * ======================================================================== */

+function ($) {
  'use strict';

  $(window).on('load', function () {

    $('.cb-sibling-menu li').on('mouseover.cbSiblingMenu focus.cbSiblingMenu', function (e) {

      $(this).siblings('.active').addClass('cb-sibling-hover');
    }).on('mouseleave.cbSiblingMenu blur.cbSiblingMenu', function (e) {

      $(this).siblings('.active').removeClass('cb-sibling-hover');
    });
  });
}(jQuery);
