/* ========================================================================
 * Tabs to Dropdown
 * ========================================================================
 *
 * $.fn.cbTabsToDropdown
 *
 * This plugin provides the tab view the ability to switch to a DropDown
 * menu on breakpoint trigger.
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  var uniqueIDCount = 0;
  cb.apricot.tabsToDropdown = function(element, options) {
    var
    defaultOptions = {

    },
    plugin = this;
    plugin.$el = $(element);

    plugin.init = function() {
      plugin.options = $.extend({}, defaultOptions, plugin.$el.data(), options);

      generateHTML();
      activateBreakpoints();

      // Force a breakpoint check
      $(document).trigger('breakpoint_change', cb.apricot.utils.viewport());
    };

    var
    generateHTML = function() {
      var
        divider = '&#8212;&#8212;&#8212;&#8212;',
        tabs = plugin.$el.children('li'),
        dropdown = $('<select class="form-control" data-cb-element="no-cb"></select>');

      // Build HTML
      tabs.each(function() {
        var tab = $(this);
        var link = tab.children('a');

        if( tab.hasClass('disabled') ) {
          // Add a disabled option, don't do anything else
          dropdown.append( $( '<option></option>' ).html( link.html() ).attr( 'disabled', true ) );
        } else {
          // If there is a Dropdown Menu, build an optgroup
          if( link.hasClass( 'dropdown-toggle' ) ) {
            // Start building an optgroup, based on the html given
            var label = $('<div>' + link.html() + '</div>');
            // Remove things we don't need
            label.find('span.sr-only').remove();
            label.find('i').remove();

            // Build a basic optgroup
            var group = $( '<optgroup></optgroup>' ).attr('label', label.text());
            var menu = tab.children( 'ul' );
            var items = menu.children( 'li' );

            // Start going through all the menu items
            items.each(function() {
              var item = $( this );
              var menuLink = item.children('a');

              if(item.hasClass('divider')) {
                // If the item is a divider, add a disabled option divider
                group.append( $( '<option></option>' ).html( divider ).attr( 'disabled', true ).addClass('cb-has-divider') );
              } else {
                // Standard case for building a dropdown
                var option = $( '<option></option>' ).html( menuLink.html() ).data( 'tab-target', menuLink ).attr( 'value', menuLink.html() );

                // If it is selected, make sure the option is selected too
                if(item.hasClass( 'active' )) {
                  option.attr( 'selected', 'selected' );
                }

                // Store a reference to the option. This will increase memory size, but lower performance costs on search
                menuLink.data( 'option-target', option );

                // Bind an event so that when we click on a tab, we select the proper option
                menuLink.on('click', function() {
                  $(this).data( 'option-target' ).attr('selected', 'selected');
                });

                // Add to the optgroup
                group.append( option );
              }
            });

            // Add to the dropdown
            dropdown.append( group );
          } else {
            // Standard case for building a dropdown
            var option = $( '<option></option>' ).html( link.html() ).data( 'tab-target', link ).attr( 'value', link.html() );
            if(tab.hasClass( 'active' )) {
              option.attr( 'selected', 'selected' );
            }

            // Store a reference to the option. This will increase memory size, but lower performance costs on search
            link.data( 'option-target', option );

            // Bind an event so that when we click on a tab, we select the proper option
            link.on('click', function() {
              $(this).data( 'option-target' ).attr('selected', 'selected');
            });

            // Add the option to the dropdown
            dropdown.append( option );
          }
        }
      });

      // Add a container
      var container = $('<div class="tabs-to-dropdown hidden-state"></div>');

      // Add ID's and stuff
      var uniqueID = 'tabToDropdown' + (uniqueIDCount++);

      // Give dropdown uniqueID
      dropdown.attr('id', uniqueID);

      // Look for a label
      if(plugin.$el.data('cb-label')) {
        container.prepend('<label for="' + uniqueID + '">' + plugin.$el.data('cb-label') + '</label>');
      }

      // Add the dropdown to the container
      container.append(dropdown);

      // Bind Event to Select
      dropdown.on('change', function() {
        var option = $(this).find('option:selected');
        var target = option.data('tab-target');

        target.click();
      });

      // Turn this into cb dropdown
      dropdown.cbCustomSelect({
        hiddenParent: true,
        parentElm: container
      });

      // Add BEFORE the real tab menu
      plugin.$el.before( container );
      plugin.$el.data( 'tabs-to-dropdown', container );

      // trigger custom event
      plugin.$el.trigger('mobiletabs_active', dropdown);
    };

    var
    showDropdown = function() {
      plugin.$el.data( 'tabs-to-dropdown' ).show();
      plugin.$el.data( 'tabs-to-dropdown' ).attr('aria-hidden', false);

      $('select', plugin.$el.data('tabs-to-dropdown')).trigger('value_changed');
    };
    var
    hideDropdown = function() {
      plugin.$el.data( 'tabs-to-dropdown' ).hide();
      plugin.$el.data( 'tabs-to-dropdown' ).attr('aria-hidden', true);
    };
    var
    showTabs = function() {
      plugin.$el.show();
      plugin.$el.attr('aria-hidden', false);
    };
    var
    hideTabs = function() {
      plugin.$el.hide();
      plugin.$el.attr('aria-hidden', true);
    };
    var
    activateBreakpoints = function() {
      // Initialize breakpoint change event
      cb.apricot.utils.breakpoints();

      $(document).on('breakpoint_change.tabsToDropdown' + '.' + uniqueIDCount, function (e, data) {

        if(data.name === 'mobile') {
          hideTabs();
          showDropdown();
        } else {
          showTabs();
          hideDropdown();
        }
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      plugin.$el.data('tabs-to-dropdown').remove();

      plugin.$el.removeData('tabs-to-dropdown');
      plugin.$el.removeAttr('aria-hidden');

      plugin.$el.css('display', 'block');

      plugin.$el.off('mobiletabs_active');
      $(document).off('breakpoint_change.tabsToDropdown' + '.' + uniqueIDCount);
      plugin.$el.removeData('cbTabsToDropdown');
    };

    plugin.init();
  };

  $.fn.cbTabsToDropdown = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbTabsToDropdown')) {
          $(this).data('cbTabsToDropdown', new cb.apricot.tabsToDropdown(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbTabsToDropdown');
        if (instance instanceof cb.apricot.tabsToDropdown && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };


  $(window).on('load', function() {
    if(!!cb.apricot.setup.isCb('tabsToDropdown')) {
      if ($('.nav-tabs').length > 0) {
        $('.nav-tabs').each(function() {
          if($(this).data('cb-element') !== 'no-cb') {
            $(this).cbTabsToDropdown();
          }
        });
      }
    }
  });
}(jQuery, cb);