/* ========================================================================
 * Resource
 * ========================================================================
 *
 * Style and behaivior adjustment for Resource elements
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  // Activate behavior Bulk icon class
  // ==============
  $(window).on('load', function () {

    // download item, accessibility
    $('.cb-resource-body').each( function () {
      var self = $(this),
        $a = $('.cb-resource-title a', self),
        $p = $('.cb-resource-info', self),
        id = '';

      if ($a.length > 0 && $p.length > 0) {
        id = (!!$p.attr('id')) ? $p.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');
        $a.attr('aria-describedby', id);
        $p.attr('id', id);
      }
    });

    // Bulk Resource in Accordion - Collapsed
    $('.cb-resource-bulk-detail').each( function () {
      var
        self = $(this),
        $more = $('.cb-resource-see-more', self),
        $title = $('.cb-resource-title', self).first(),
        title = (!!$title) ? $title.html() : '',
        $container = $('.collapse', self),
        id = (!!$container.attr('id')) ? $container.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');

      $more.attr('aria-label', 'See More (' + title + ')')
        .attr('aria-controls', id)
        .attr('aria-expanded', false);

      $container.attr('id', id);

      $more.on('click', function (e) {
        e.preventDefault();

        if (!!$more.hasClass('open')) {
          $more.removeClass('open')
            .attr('aria-label', 'See More (' + title + ')')
            .attr('aria-expanded', false);

          $container.slideUp( 300, function() {
            $(this)
              .removeClass('in');
          });
        } else {
          $more.addClass('open')
            .attr('aria-label', 'See Less (' + title + ')')
            .attr('aria-expanded', true);

          $container
              .addClass('in')
              .slideDown( 300);
        }
      });
    });

    // Bulk Resource in Modal
    $('.cb-resource-modal .cb-resource-bulk-detail').each( function () {
      var
      self = $(this),
      $body = $('.cb-resource-download-list', self),
      $x = $('<button>')
        .attr('type', 'button')
        .addClass('close')
        .attr('data-dismiss', 'modal')
        .attr('aria-label', 'Close')
        .html('<span aria-hidden="true">×</span>');

      $x.insertBefore($body);
    });

    // Module View Resource, height adjustment
    adjustResourceHeight();

    $(window).on('resize.adjustResourceHeight', function () {
      adjustResourceHeight();
    });
  });

  function adjustResourceHeight () {
    $('.cb-resource-container').each( function () {
      // reset all;
      $('.cb-resource-desc', $(this)).css('margin-bottom', '0');

      var
        res = cb.apricot.utils.tallestElm($('.cb-resource-module', $(this)));

      cb.apricot.utils.adjustHeight(res, '.cb-resource-desc');
    });
  }
}(jQuery, cb);
