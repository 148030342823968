/* ========================================================================
 * Tables
 * ========================================================================
 *
 * Style and behaivior adjustment for Tables
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.stickyColumn = function (element, options) {
    var
      defaultOptions = {
        stickyColContainer : 'cb-sticky-column',
        stickyNavContainer : 'cb-sticky-nav-bar',
        columns: 1,
        borderWidth: 5
      },

      border = 0,
      plugin = this;

    plugin.$el = $(element);
    plugin.$parent = {};
    plugin.$stickyColumn = {};
    plugin.$stickyNav = {};
    var
    buildStickyColumn = function () {
      var
        $sticky = $('<div />').addClass(plugin.options.stickyColContainer),
        $tbl = plugin.$el.clone()
          .appendTo($sticky);

      plugin.$stickyColumn = $sticky;

      // Accessibility updates
      $tbl.attr('aria-hidden', true)
        .attr('role', 'presentation')
        .attr('tabindex', '-1');

      $('thead tr, tbody tr', plugin.$el).each( function (index1) {
        var $tr = $(this);

        $('th, td', $tr).each (function (index2) {
          if (index2 < plugin.options.columns) {
            $(this).attr('data-cb-sticky', index1 + '-' + index2);
          }
        });
      });


      $('thead tr, tbody tr', $tbl).each( function (index1) {
        var $tr = $(this);

        $('th, td', $tr).each (function (index2) {
          var self = $(this);

          self.attr('data-cb-sticky', index1 + '-' + index2);

          if (index2 >= plugin.options.columns) {
            self.remove();
          }
        });
      });

      adjustCellSize();

      // insert before table
      plugin.$el.before($sticky);
    },

    buildStickyNav = function () {
      var $div = $('<div />')
          .addClass(plugin.options.stickyNavContainer),
          $a = $('<a />')
          .attr('tabindex', 0)
          .attr('title', 'scroll table to right')
          .attr('href', '#').appendTo($div);

        $('<span />')
          .addClass('cb-glyph')
          .addClass('cb-east')
          .attr('aria-hidden', true).appendTo($a);

        $('<span />')
          .addClass('cb-glyph')
          .addClass('cb-east')
          .attr('aria-hidden', true).appendTo($a);

        $('<span />')
          .addClass('cb-glyph')
          .addClass('cb-east')
          .attr('aria-hidden', true).appendTo($a);

      plugin.$stickyNav  = $div;

      plugin.$el.before($div);
      addNavEvents ();
    },

    addNavEvents = function () {
      var $scrollbarContainer = plugin.$el.closest('.cb-scrollbar-container'),
        $scrollbarTop = $('.cb-scrollbar.top', $scrollbarContainer);

      plugin.$stickyNav.on('click', function (e) {
        e.preventDefault();

        $scrollbarTop.trigger('click', e);
      }).on('keydown', function (e) {
        // e.preventDefault();
        if (e.which == 37 || e.which == 39) {
          $scrollbarContainer.trigger('keydown', e);
        }

        $scrollbarTop.trigger('keydown', e);
      });
    },

    adjustCellSize = function () {
      var containerWidth = 0;

      $('thead tr, tbody tr', plugin.$el).each( function (index) {
        var $tr = $(this);

        $('th, td', $tr).each ( function () {
          var
          self = $(this),
          cell = self.attr('data-cb-sticky'),
          width = self.outerWidth(true),
          height = self.outerHeight(true);

          self.css({
            'width': width + 'px',
            'min-width': width + 'px',
            'height': height + 'px',
            'min-height': height + 'px'
          });

          if (!!cell) {
            $('th[data-cb-sticky="' + cell + '"], td[data-cb-sticky="' + cell + '"]', plugin.$stickyColumn)
              .css({
                'width': width + 'px',
                'min-width': width + 'px',
                'height': height + 'px',
                'min-height': height + 'px'
              });

            if (index == 0) {
              containerWidth += width;
            }
          }
        });
      });
      containerWidth += (5 - parseInt(border, 10));

      plugin.$stickyColumn.css('width', containerWidth+ 'px');
    };


    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);
      border = (plugin.$el.find('th:first-child').outerWidth() - plugin.$el.find('th:first-child').innerWidth()) / 2;
      plugin.$parent = plugin.$el.closest('.table-responsive');

      buildStickyColumn();
      buildStickyNav();

      //Check structure on resize
      $(window).on('resize.cbStickyColumn', function () {
        adjustCellSize();
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {

      plugin.$stickyColumn.remove();
      plugin.$stickyNav.remove();

      plugin.$el.removeData('cbStickyColumn');
    };

    plugin.init();
  };

  $.fn.cbStickyColumn = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbStickyColumn')) {
          $(this).data('cbStickyColumn', new cb.apricot.stickyColumn(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbStickyColumn');
        if (instance instanceof cb.apricot.stickyColumn && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);