/* ========================================================================
 * Legend
 * ========================================================================
 *
 * Behaivior adjustment for Legend
 * ======================================================================== */


+function ($, cb) {
  'use strict';

  if (!$.fn.popover) throw new Error('cbPopOverListItems requires popover.js');
  cb.apricot.popOverLegend = function (element, options) {
    var
      defaultOptions = {
        btnClass: 'cb-legend-btn',
        dataClass: 'cb-legend-data',
        contentClass: 'cb-legend-content',
        arrowClass: 'cb-legend-arrow'
      },
      plugin = this,
      $btn = {};

    plugin.$el = $(element);
    plugin.$modal = {};
    plugin.btnId = '';
    plugin.viewport = '';

    var
    addBtnEvents = function () {
      $btn.on('keydown.cbLegend', function (e) {
        if (cb.apricot.utils.isKey(e, 'SPACE') || cb.apricot.utils.isKey(e, 'ENTER')) {
          e.preventDefault();

          $btn.trigger('click');
        }
      }).on('click.cbLegend', function (e) {
        e.preventDefault();

        // check which one to activate
        if (plugin.viewport === 'xs') {
          plugin.$modal.modal();
        } else {
          $btn.popover('toggle');
        }
      });
    },

    activatePopOver = function () {
      $btn.popover({
        html: true,
        trigger: 'manual',
        title: '',
        content: function () {
          return $('.' + plugin.options.dataClass, plugin.$el).html();
        },
        placement: function (context) {
          $(context).addClass(plugin.options.contentClass);
          return 'bottom';
        }
      }).on('shown.bs.popover', function () {
        var
          $popup = $(this),
          $content = $('.popover'),
          contentId = $content.attr('id');

        $content.attr('role', 'dialog')
          .attr('aria-label', 'Legend');

        $btn.attr('aria-expanded', 'true')
          .removeAttr('aria-describedby')
          .attr('aria-controls', contentId);

        $('.' + plugin.options.arrowClass, $popup).removeClass('cb-down')
          .addClass('cb-up');

        $('.popover-content', $content).attr('tabindex', 0)
          .attr('role', 'document')
          .attr('aria-describedby', contentId)
          .focus();

        $('.popover-content', $content).keydown(function (e) {
          if (!!cb.apricot.utils.isKey(e, 'ESC')) { //esc
              e.preventDefault();
              $popup.popover('hide');
          }

          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
              e.preventDefault();
              $popup.popover('hide');
              $btn.focus();
          }

          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
              e.preventDefault();
              $popup.popover('hide');
              $btn.focus();
          }
        });
      }).on('hide.bs.popover', function () {
        var $popup = $(this);

        $('.' + plugin.options.arrowClass, $popup)
          .addClass('cb-down')
          .removeClass('cb-up');

        $btn.attr('aria-expanded', 'false')
          .removeAttr('aria-controls')
          .focus();
      });
    },

    activateModal = function() {
      var
        $divModal = $('<div />')
          .addClass('modal')
          .addClass('cb-modal-animation')
          .addClass('fade')
          .addClass('cb-legend-modal')
          .attr('tabindex', '-1')
          .attr('role', 'dialog')
          .attr('aria-label', 'legend modal')
          .attr('id', plugin.btnId + 'Modal'),

        $divDialog = $('<div />')
          .addClass('modal-dialog')
          .attr('role', 'document')
          .appendTo($divModal),

        $divContent = $('<div />')
          .addClass('modal-content')
          .addClass('cb-no-footer')
          .appendTo($divDialog),

        $divHeader = $('<div />')
          .addClass('modal-header')
          .appendTo($divContent),

        $btn = $('<button />')
          .addClass('close')
          .attr('data-dismiss', 'modal')
          .attr('aria-label', 'Close')
          .appendTo($divHeader),

        $tmp = $('.' + plugin.options.dataClass, plugin.$el).clone();

        $('<h4 />')
          .addClass('modal-title')
          .html($('.cb-legend-title', $tmp).html())
          .appendTo($divHeader);

        $('<span />')
          .attr('aria-hidden', true)
          .html('×')
          .appendTo($btn);


        $tmp.removeClass('cb-legend-data')
          .addClass('cb-legend-content');

        $('.cb-legend-title', $tmp).remove();

        $('<div />')
          .addClass('modal-body')
          .append($tmp)
          .appendTo($divContent);

      // add modal to page
      $('body').append($divModal);

      plugin.$modal = $divModal;

      addModalEvents();
    },

    addModalEvents = function () {
      plugin.$modal.on('shown.bs.modal', function () {
        $btn.attr('aria-expanded', 'true');

       $('.close', plugin.$modal).focus();
      }).on('hidden.bs.modal', function () {
        $btn.attr('aria-expanded', 'false')
          .focus();
      });
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);

      $btn = $('.' + plugin.options.btnClass, plugin.$el);
      plugin.viewport = cb.apricot.utils.viewport().prefix;

      plugin.btnId = (!!$btn.attr('id')) ? $btn.attr('id') : cb.apricot.utils.uniqueID(5, 'apricot_');
      $btn.attr('id', plugin.btnId);

      activatePopOver();
      activateModal();
      addBtnEvents();

      $(window).on('resize.cbLegend', function () {
        plugin.viewport = cb.apricot.utils.viewport().prefix;
        if ($btn.attr('aria-expanded') == 'true') {
          if (plugin.viewport === 'xs') {
            if (!!$('.popover.cb-legend-content').hasClass('in')) {
              $btn.popover('hide');
            }
          } else {
            plugin.$modal.modal('hide');
          }
        }
      });
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      plugin.$el.removeData('cbLegend');
      $(window).removeData('resize.cbLegend');

      plugin.$modal.remove();
      $btn.popover('destroy')
        .off('keydown.cbLegend')
        .off('click.cbLegend');
    };

    plugin.init();
  };

  $.fn.cbLegend = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbLegend')) {
          $(this).data('cbLegend', new cb.apricot.popOverLegend(this, options));
        }
       });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbLegend');
        if (instance instanceof cb.apricot.popOverLegend && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call(args, 1) );
        }
      });
    }
  };
}(jQuery, cb);
