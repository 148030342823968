/*!
 *  Apricot v3.2.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-02-26 [5:55:31 PM] UTC
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Alert extension
 * ========================================================================
 *
 * This extension add the ability to hide the Alert block instead of
 * removing it from the page
 * ======================================================================== */

+function ($) {
  'use strict';

  // Hide Alert element
  // ==============
  $(window).on('load', function () {
    $('[data-cb-hide="alert"]').each(function () {
      $(this).on('click', function(e) {
        e.preventDefault();

        var $alert = $(this).closest('.alert');

        $alert.addClass('hidden');
      });
    });
  });
}(jQuery);
