/* ========================================================================
 * Filters
 * ========================================================================
 *
 * Style and behaivior adjustment for Filters
 * ======================================================================== */


+function ($, cb) {
  'use strict';

  cb.apricot.filter = function (element, options) {
    var
      defaultOptions = {
        cbFilterCollapse: 'data-cb-collapse',
        containerClass: 'cb-filter-container',
        selectedContainerClass: 'cb-filter-selected',
        selectDisplayType: 'grid',
        tagClass: 'cb-filter-tag',
        tagsContainerClass: 'cb-filter-tags-container',
        btnFilterClass: 'btn-secondary-group',
        handelTagsContainer: true,
        removeFilterCount: 0
      },

      $filterCollapse = {},
      $filterContainer = {},
      $selectedContainer = {},
      filterItems = [],
      plugin = this;

    plugin.$el = $(element);
    plugin.$modal = {};
    plugin.collapseId = '';
    plugin.viewport = '';

    var
    addBtnEvents = function () {
      plugin.$el.on('click.cbFilter', function (e) {
        e.preventDefault();

        // check which one to activate
        if (plugin.viewport === 'xs') {
          plugin.$el.addClass('cb-open');
          plugin.$modal.modal();
        } else {
          accordionEffect();
        }
      });
    },

    accordionEffect = function () {
      var $parent = plugin.$el.parent();
      if ($filterCollapse.css('display') !== 'none') {
        plugin.$el.removeClass('cb-open');
        $parent.removeClass('cb-open');
      } else {
        plugin.$el.addClass('cb-open');
        $parent.addClass('cb-open');
      }

      $filterCollapse.slideToggle(cb.apricot.data.transitionDuration, 'linear', function() {
        var self = $(this);

        if (self.css('display') === 'none') {
          self.removeClass('in');
          $filterContainer.removeClass('in');
          plugin.$el.trigger('accordion.close.cbFilter');
        } else {
          self.addClass('in');
          $filterContainer.addClass('in');
          plugin.$el.trigger('accordion.open.cbFilter');
        }

        addAriaRoles();
        showHideTags();
      });
    },

    showHideTags = function () {
      if (!!plugin.options.handelTagsContainer) {

        if (filterItems.length > 0)  {
          $selectedContainer.css('display', plugin.options.selectDisplayType);
          $filterContainer.removeClass('cb-filter-no-tag');
        } else {
          $selectedContainer.css('display', 'none');
          $filterContainer.addClass('cb-filter-no-tag');
        }
        showHideRemove();
      }
    },

    showHideRemove = function () {
      if (!isNaN(plugin.options.removeFilterCount)) {
        var display = 'none';

        if (filterItems.length > parseInt(plugin.options.removeFilterCount, 10)) {
          display = 'block';
          $selectedContainer.attr('aria-label', 'applied filters (' + filterItems.length + ' filter(s) applied)');
        } else {
          $selectedContainer.attr('aria-label', 'applied filters (no filters are currently applied)');
        }

        $('.' + plugin.options.btnFilterClass, $filterContainer).css('display', display);
      }
    },

    // accessibility improvment
    addAriaRoles = function () {
      var isOpen = $filterCollapse.hasClass('in'),
      tabIndex = (!!isOpen) ? -1 : 0;

      $filterCollapse.attr('aria-expanded', isOpen)
        .attr('aria-hidden', !isOpen)
        .attr('tabindex', tabIndex);

      plugin.$el.attr('aria-expanded', isOpen);
    },

    buildFilterTags = function (obj, id) {
      var
        label = obj.label,
        $container = $('.' + plugin.options.tagsContainerClass, $filterContainer),
        $btn = $('<button/>')
          .attr('type', 'button')
          .addClass('btn')
          .addClass('btn-xs')
          .addClass('btn-tag')
          .addClass(plugin.options.tagClass)
          .html(label + ' '),
        $span = $('<span/>')
          .addClass('cb-glyph')
          .addClass('cb-x-mark')
          .attr('aria-hidden' ,'true');

      $('<span />')
        .addClass('sr-only')
        .html('remove filter')
        .appendTo($btn);

      $span.appendTo($btn);

      $btn.attr('id', id)
        .appendTo($container);

        obj.id = id;

      $btn.on('click.cbFilter', function (e) {
        e.preventDefault();

        plugin.$el.trigger('remove.cbFilter', obj);
      });
    },

    buildModal = function() {
      var
        $divModal = $('<div />')
          .addClass('modal')
          .addClass('cb-modal-animation')
          .addClass('fade')
          .addClass('cb-filter-modal')
          .attr('tabindex', '-1')
          .attr('role', 'dialog')
          .attr('aria-label', 'filter modal')
          .attr('id', plugin.collapseId + 'Modal'),

        $divDialog = $('<div />')
          .addClass('modal-dialog')
          .attr('role', 'document')
          .appendTo($divModal),

        $divContent = $('<div />')
          .addClass('modal-content')
          .addClass('cb-no-footer')
          .appendTo($divDialog),

        $divHeader = $('<div />')
          .addClass('modal-header')
          .appendTo($divContent),

        $btn = $('<button />')
          .addClass('close')
          .attr('data-dismiss', 'modal')
          .attr('aria-label', 'Close')
          .appendTo($divHeader),

        $tmp = {};
        $tmp = $filterContainer.clone();

        $('<h4 />')
          .addClass('modal-title')
          .html('Filters')
          .appendTo($divHeader);

        $('<span />')
          .attr('aria-hidden', true)
          .html('×')
          .appendTo($btn);

        $tmp.addClass('cb-filter-content');

        $('<div />')
          .addClass('modal-body')
          .append($tmp)
          .appendTo($divContent);

        $('.hidden-xs', $divModal).removeClass('hidden-xs');

      // add modal to page
      $('body').append($divModal);

      $('.collapse', $divModal).removeClass('collapse');

      plugin.$modal = $divModal;
      $filterContainer = $('.cb-filter-container', plugin.$modal);

      addModalEvents();
    },

    addModalEvents = function () {
      plugin.$modal.on('shown.bs.modal', function () {
        plugin.$el.attr('aria-expanded', 'true');

       $('.close', plugin.$modal).focus();
      }).on('hidden.bs.modal', function () {
        plugin.$el.attr('aria-expanded', 'false')
          .removeClass('cb-open')
          .focus();
      });
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);
      plugin.viewport = cb.apricot.utils.viewport().prefix;

      plugin.collapseId = (!cb.apricot.utils.isBlank(plugin.options.cbFilterCollapse)) ?
        plugin.$el.attr(plugin.options.cbFilterCollapse) : '';
      $filterCollapse = $('#' + plugin.collapseId);

      if ($filterCollapse.length <= 0 ) {
        return false;
      }


      $filterContainer = (!!$filterCollapse.hasClass(plugin.options.containerClass)) ?
        $filterCollapse : $filterCollapse.parent();

      $selectedContainer = $('.' + plugin.options.selectedContainerClass, $filterContainer);
      $selectedContainer.attr('tabindex', '-1');

      // accessibility improvment
      plugin.$el.attr('aria-controls', plugin.collapseId);

      if (plugin.viewport === 'xs') {

        // generate markup for modal
        buildModal();
      }

      addAriaRoles();
      addBtnEvents();
      showHideTags();
    };

    // Add filter tag
    plugin.addFilterTag = function(obj) {
      var id = cb.apricot.utils.uniqueID(5, 'apricot');

      filterItems.push(id);
      buildFilterTags(obj, id);

      showHideTags();
    };

    // Close accordion
    plugin.closeAccordion = function(mode) {
      if (!!plugin.$el.hasClass('cb-open')) {
        accordionEffect(mode);
      }
    };

    // Close modal
    plugin.closeModal = function() {
      if (!!plugin.$el.hasClass('cb-open')) {
        plugin.$el.removeClass('cb-open');
        plugin.$modal.modal('hide');
      }
    };

    // Remove filter tag
    plugin.removeFilterTag = function(id) {
      var $elm = $('#' + id),
      $next = $elm.next('.' + plugin.options.tagClass);

      filterItems.splice($.inArray(id, filterItems), 1);

      $('#' + id).remove();
      // set focus to next tag
      if ($next.length > 0) {
        $next.focus();
      }

      showHideTags();
    };

    // Remove all filter tag
    plugin.removeAllFilterTag = function() {
      $('.btn-tag', $filterContainer).remove();

      filterItems = [];
      showHideTags();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {

      // remove filter modal
      $('.cb-filter-modal').remove();

      plugin.$el.off('accordion.close.cbFilter')
        .off('accordion.open.cbFilter')
        .off('remove.cbFilter');

      plugin.$el.removeData('cbFilter');
    };

    plugin.init();
  };

  $.fn.cbFilter= function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function() {
        if (!$(this).data('cbFilter')) {
          $(this).data('cbFilter', new cb.apricot.filter(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function() {
        var instance = $.data(this, 'cbFilter');
        if (instance instanceof cb.apricot.filter && typeof instance[options] === 'function') {
          instance[options].apply(instance, Array.prototype.slice.call(args, 1));
        }
      });
    }
  };
}(jQuery, cb);