/*!
 *  Apricot v3.2.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-02-26 [5:55:31 PM] UTC
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Popover Extension
 * ========================================================================
 *
 * This extension closes all open popOvers
 * when the user resizes the page
 * ======================================================================== */

+function ($) {
  'use strict';

  if (!$.fn.popover) throw new Error('popover-extension requires popover.js');

  //Close all popOvers on window resize
  $(window).on('resize.cbPopover', function () {
    $('[data-toggle="popover"]').each(function () {
      var $popover = $(this);

      $popover.popover('hide');
    });
  });

  //Close popOver when clicking outside active one
  $(document).on('click.cbPopover', function (e) {
      $('[data-toggle="popover"]').each(function () {
        var
          self = $(this);

        if (!self.is(e.target) && self.has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
          self.popover('hide');
        }
    });
  });

  //Prevent default behavior for CB's custom popOvers
  $(window).on('load.cbPopover', function () {
    $('.cb-popover-button-list, .cb-popover-link-list').each(function () {
      $(this).on('click', function (e) {

        e.preventDefault();
      });
    });
  });

}(jQuery);
