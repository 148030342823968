/* ========================================================================
 * Video Modals
 * ========================================================================
 *
 * Style and behaivior adjustment for Video Modals
 * ======================================================================== */

+function ($, cb) {
  'use strict';

  cb.apricot.videoModal = function  (element, options) {
    var
      defaultOptions = {
      	src: ''
      },
      $iframe = {},
      plugin = this;

    plugin.$el = $(element);

    var
    events = function () {
    	var
        $modal = plugin.$el;

      $modal.on('show.bs.modal', function() {
        $iframe.focus();
        $iframe.attr('src', plugin.src);
      });

      // remove src to stop the video playing
      $modal.on('hide.bs.modal', function() {

        $iframe.attr('src', '');
      });

      $modal.on('shown.bs.modal', function() {
        var
          $close = $('.close', $modal),
          $frame = $('.cb-modal-frame', $modal);

        $frame.keydown(function(e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && !e.shiftKey) { //tab
              e.preventDefault();
              $close.focus();
          }

          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $iframe.focus();
          }
        });

        $iframe.keydown(function(e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $close.focus();
          }
        });

        $close.keydown(function(e) {
          if (!!cb.apricot.utils.isKey(e, 'TAB') && e.shiftKey) { //shift & tab
            e.preventDefault();
            $iframe.focus();
          }
        });
      });
	  },

    addTabStopper = function () {
      var $a = $('<a />')
        .addClass('cb-modal-frame')
        .attr('aria-hidden', true)
        .attr('href', '#');

      $('.modal-body', plugin.$el).append($a);
    };

    plugin.init = function () {
      plugin.options = $.extend({}, defaultOptions, options);
      $iframe = $('iframe', plugin.$el);
      plugin.src = '';

      plugin.src = (plugin.options.src !== '') ? plugin.options.src :
      	(($iframe.length > 0) ?
      			$iframe.attr('src') : '');

      if (plugin.src === '') {

      	return false;
      }

      // make sure there is no autoplay on page load
      $iframe.attr('src', '');

      // Accessibility
      addTabStopper();

      events();
    };

    // Remove plugin instance and clean up
    plugin.destroy = function () {
      if (!!plugin.$el.data('cbVideoModal')) {

        // make sure we put back src
        $iframe.attr('src', plugin.src);
        plugin.$el.removeData('cbVideoModal');
      }
    };

    plugin.init();
  };

  $.fn.cbVideoModal = function (options) {
    var args = arguments;
    if (options === undefined || typeof options === 'object') {
      return this.each(function () {
        if (!$(this).data('cbVideoModal')) {
          $(this).data('cbVideoModal', new cb.apricot.videoModal(this, options));
        }
      });
    } else if (typeof options === 'string') {
      return this.each(function () {
        var instance = $.data(this, 'cbVideoModal');
        if (instance instanceof cb.apricot.videoModal && typeof instance[options] === 'function') {
          instance[options].apply( instance, Array.prototype.slice.call( args, 1 ) );
        }
      });
    }
  };

  // Activate behavior
  // ==============
  $(window).on('load.videoModals', function () {
  	$('.cb-video-modal').each(function () {
  		$(this).cbVideoModal();
  	});
  });
}(jQuery, cb);
