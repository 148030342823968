/*!
 *  Apricot v3.2.0
 *  By:             The College Board
 *  App:            apricot
 *  Build Time:     2019-02-26 [5:55:31 PM] UTC
 *  Build Number:   unknown
 *  SVN Revision:   unknown
 *  Jenkins Job:    unknown
 *  This version of Apricot includes Bootstrap v3.7.0
 */

/* ========================================================================
 * Tooltip extension
 * ========================================================================
 *
 * This extension fixes the tooltip positioning issue when the hover
 * control has a line break
 * ======================================================================== */

+function ($) {
  'use strict';

  if (!$.fn.tooltip) throw new Error('tooltip-extension requires tooltip.js');

  $.fn.tooltip.Constructor.prototype.getCalculatedOffset = function (placement, pos, actualWidth, actualHeight) {
    var
      defaultHeight = 0,
      defaultWidth = 0,
      currentHeight = pos.height,
      $tmp = this.$element.clone()
      .css({
        'position': 'absolute',
        'left': '-9999px'
      });

    $tmp.appendTo('body');
    defaultHeight = $tmp.outerHeight();
    defaultWidth = $tmp.outerWidth();
    $tmp.remove();

    // There was a line break
    if (currentHeight !== defaultHeight) {
      pos.width = defaultWidth ;
      //IE8 bug
      if (!isNaN(pos.right)) {
        pos.left = pos.right - defaultWidth;
      }
    }

    return placement == 'bottom' ? { top: pos.top + pos.height,   left: pos.left + pos.width / 2 - actualWidth / 2  } :
           placement == 'top'    ? { top: pos.top - actualHeight, left: pos.left + pos.width / 2 - actualWidth / 2  } :
           placement == 'left'   ? { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left - actualWidth } :
        /* placement == 'right' */ { top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left + pos.width   };
  };

  $.fn.tooltip.Constructor.prototype.init = function (type, element, options) {
    this.enabled   = true
    this.type      = type
    this.$element  = $(element)
    this.options   = this.getOptions(options)
    this.$viewport = this.options.viewport && $($.isFunction(this.options.viewport) ? this.options.viewport.call(this, this.$element) : (this.options.viewport.selector || this.options.viewport))
    this.inState   = { click: false, hover: false, focus: false }

    if (this.$element[0] instanceof document.constructor && !this.options.selector) {
      throw new Error('`selector` option must be specified when initializing ' + this.type + ' on the window.document object!')
    }

    var triggers = this.options.trigger.split(' ')

    for (var i = triggers.length; i--;) {
      var trigger = triggers[i]

      if (trigger == 'click') {
        this.$element.on('click.' + this.type, this.options.selector, $.proxy(this.toggle, this))
      } else if (trigger != 'manual') {
        var eventIn  = trigger == 'hover' ? 'mouseenter' : 'focusin'
        var eventOut = trigger == 'hover' ? 'mouseleave' : 'focusout'

        this.$element.on(eventIn  + '.' + this.type, this.options.selector, $.proxy(this.enter, this))
        this.$element.on(eventOut + '.' + this.type, this.options.selector, $.proxy(this.leave, this))
      }
    }

    // Accessibility improvment
    if (!this.$element.is('a') && !this.$element.is('button')) {
      this.$element.attr('role', 'link')
        .attr('aria-label', 'tooltip')
        .attr('tabindex', 0);
    }

    this.options.selector ?
      (this._options = $.extend({}, this.options, { trigger: 'manual', selector: '' })) :
      this.fixTitle()
  };

  // Activate cb-tooltip-dark class
  // ==============
  $(window).on('load.cbTooltip', function () {
    $('.cb-tooltip-dark').each(function () {
      var
        self = $(this),
        darkTemplate = '<div class="tooltip cb-tooltip-dark"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

      self.tooltip({
        template: darkTemplate
      });
    });
  });

}(jQuery);
