/* ========================================================================
 * Apricot's clickJacking Module
 * ========================================================================
 *
 * Function to prevent clickJacking
 * ======================================================================== */


+function ($, cb) {
  'use strict';

  function _clickJacking () {
    try {
      var d = document.documentElement,
      ws = window.self,
      wt = window.top;

      if (ws != wt) {
        d.style.display = 'none';
        d.style.visibility = 'hidden';
        wt.location = ws.location;

        console.log('This content cannot be displayed in a frame.');
        return false;
      } else {

        return true;
      }
    } catch (e) {
      return false;
    }
  }

  cb.apricot.clickJacking = _clickJacking;

  // Activate clickJacking
  // ==============
  $(window).on('load', function () {
    if (!!cb.apricot.setup.isCb('clickJacking')) {
      if ($('body').data('cb-element') !== 'no-cb') {

        cb.apricot.clickJacking();
      }
    }
  });
}(jQuery, cb);
